import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"

import Head from "../components/Head"
import Template from "../components/Template"

export default () => {
  const basics = require("../cms/basics.json")

  const query = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const photoName = basics.homepage_photo.replace("/assets/", "")
  const photo = query.images.edges.find(node => node.node.relativePath === photoName).node.childImageSharp.fluid

  return (
    <>
      <Head />

      <Template
        h1={basics.site_name}
        h2={basics.subtitle}
        hideTitle={true}
        lessGap
      >
        <p
          css={css`
            text-align: center;
            color: var(--text-gray);
            margin-top: 16px;
            margin-bottom: 16px;
            font-size: 1.1rem;
            line-height: 1.4;
          `}
        >
          {basics.homepage_caption}
        </p>

        <Img
          fluid={photo} 
          css={css`
            border-radius: 4px
          `}
        />
      </Template>
    </>
  )
}